import React from 'react';
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <body>
      <div id="center">
        <p> <br /> </p>
        <h3> This page does not exist. </h3>
        <h3> Nid yw'r dudalen hon yn bodoli. </h3>
      </div>
    </body>
  );
}


export default NotFound;
